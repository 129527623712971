import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import Header from "../components/header"

const RentalAgreementPage = () => {
  return <Layout title="Rental Agreement">
    <Header title="Grass Top Games Rental Agreement" />

    <section className="py-5 mt-5">
      <Container>
        <Row>
          <Col>
            <h3>Payment</h3>
            <ul>
            <li>A reservation is only confirmed after full payment is received when scheduling.</li>
            </ul>

            <h3>Drop Off</h3>
            <ul>
              <li>Games will be delivered between 10-11AM on the day of the rental. </li>
              <li>We will call on arrival, and the customer needs to be available to accept the games. </li>
              <li>Games will be delivered to the customer's driveway/curb. Game setup is not included. </li>
              <li>Customers should expect games to have normal wear and tear from being used in an appropriate way. Color/style of games may vary.</li>
              <li>If the customer who scheduled the rental is not available to receive the games on the day of the rental, the customer needs to notify Grass Top Games by text or email with the name and contact information of an alternative person who will accept and sign for the games. </li>
            </ul>

            <h3>Pick Up</h3>
            <ul>
              <li>Games need to be ready for pick up by 9AM the next day. Pick up will occur between 9-10AM.</li>
              <li>Games need to be clean, dry, and packaged as they were when delivered.</li>
              <li>We will call on arrival to pick up games from the same driveway/curb where they were delivered.</li>
              <li>Customer is responsible for the games until we pick them up. </li>
              <li>We will inspect the games at pick up to make sure they are returned in the condition they were received (clean, dry, and no damaged or missing pieces).</li>
              <li>If a customer misses our pick up attempt, they will be charged for an additional day of rental. Pick up for games will be attempted again the next day.</li>
            </ul>

            <h3>Cancellation Policy</h3>
            <ul>
              <li>In the event the customer needs to cancel, the customer needs to notify Grass Top Games by email (<a href="mailto:hello@grasstopgames.com">hello@grasstopgames.com</a>) by 10AM the day before the rental in order to receive a 100% refund. </li>
              <li>Any cancellation after 10AM the day before the rental will not receive a refund. </li>
            </ul>

            <h3>Damage/Lost Items</h3>
            <ul>
              <li>Games will be assessed for damage when we pick them up.</li>
              <li>The credit card used for the reservation will be charged a fee for games that are damaged, beyond normal wear and tear.</li>
              <li>The credit card used for the reservation will be charged a replacement fee for games that are lost or missing pieces.</li>
            </ul>

            <h3>Game Becomes Unavailable</h3>
            <ul>
              <li>Should a game become unavailable to rent due to unforeseen circumstances, Grass Top Games will offer an available alternative game or discount your rental.</li>
            </ul>

            <h3>Game Usage</h3>
            <ul>
              <li>Games are to be played on grass to prevent damage.</li>
              <li>The customer is responsible for setting up games in a way that prevents damage to games, personal property, and people. Games are not to be misused in a manner that could cause damage to the game, personal property, or harm to someone.</li>
              <li>Games are intended to be played in a reasonable and respectful manner according to the instructions provided.</li>
              <li>If there is inclement weather, it is the responsibility of the customer to move the games to a dry location to protect them from sustaining water damage.</li>
            </ul>

            <h3>Assumption of Risk, Release of Liability</h3>
            <ul>
              <li>INDEMNITY/HOLD HARMLESS: Customer will take all necessary precautions regarding the items rented and protect all persons and property from injury or damage. Customer agrees to hold harmless Grass Top Games, LLC from and against any and all liability, claims, judgements, attorneys' fees and cost of every kind, including, but not limited, to injuries or death to persons and damage to property, arising out of the use, operation, possession, or rental of the items rented.</li>
              <li>ASSUMPTION OF RISK/DISCHARGE OF LIABILITY: Customer is fully aware and acknowledges there is a risk of injury or damage arising out of the use or operation of the items rented here under and hereby elects to voluntarily enter into this Rental Agreement and assume all of the above risk of injury or damage. Customer agrees to release and discharge Grass Top Games, LLC from any and all responsibility or liability from such injury or damage arising out of the use or operations of the rental items; and customer further agrees to waive, release and discharge any and all claims for injury or damage against Grass Top Games, LLC which customer otherwise may be entitled to assert.</li>
            </ul>
          </Col>
        </Row>    
      </Container>
    </section>
  </Layout>
}

export default RentalAgreementPage
